import { Injectable } from '@angular/core';
import axios, { AxiosInstance, AxiosResponse } from 'axios';

@Injectable({
  providedIn: 'root'
})
export class PlatformService {

  constructor() { }

  public async postRaw(props: PostAxiosCallProperties) {

    try {

      return await axios.post(props.url, props.body, {
        headers: props.headers,
        params: props.params,
        validateStatus: props.validateStatus
      });

    }
    catch (error) {
      this.handleError(error, props.url, 'Platform - Dependency Error - url - [POSTRAW]');
    }

    return;
  }
  public async getRaw(props: PostAxiosCallProperties) {

    try {

      return await axios.get(props.url, {
        headers: props.headers,
        params: props.params,
        validateStatus: props.validateStatus
      });

    }
    catch (error) {
      this.handleError(error, props.url, 'Platform - Dependency Error - url - [GETRAW]');
    }

    return;
  }
  private handleError(error: any, url: string, metric: string) {
    let logMessage = `${error.message} - ${url}`

    if (error.response) {
      logMessage += ` - Response: '${JSON.stringify(error.response.data)}'`;
    }

    console.log(`${logMessage}`);

    // here we need to flag this up to some sort of analytics

    throw new Error(`Axios request failed. ${logMessage}`);
  }
}
export interface AxiosCallProperties {
  url: string,
  headers?: { [key: string]: string },
  params?: URLSearchParams,
  validateStatus?: (status: number) => boolean
}
export interface PostAxiosCallProperties extends AxiosCallProperties {
  body?: any
}

