import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { getAuth } from 'firebase/auth';
import { Router } from '@angular/router';
import { from, of, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PlatformService } from './platform.service';

declare let window: any;

@Injectable({
  providedIn: 'root'
})
export class MetamaskAuthService {

  readonly ENDPOINT: string = environment.platformEndpoint;

  constructor(
    protected auth: AngularFireAuth,
    protected platformService:PlatformService,
    protected router: Router,
  ) { }

  get user():any{
    if (localStorage.getItem("user") && localStorage.getItem("user") !== "null") {
      return JSON.parse(localStorage.getItem("user")!);
    } else {
      return "";
    }
  }
  async token(forceRefresh?: boolean): Promise<string> {
    return new Promise(async (resolve) => {
      await this.auth.onAuthStateChanged((user) => {
        if (user) {
          resolve(user?.getIdToken(forceRefresh || false));
        } else {
          resolve("");
        }
      });
    });
  }

  public async authWithMetaMask() {
    try {
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      const getNonceToSign = await this.platformService.postRaw(
        {
          url: this.ENDPOINT + '/metamask/getNonceToSign',
          body: {
            "address": accounts[0],
            "createUser": false,
          }
        }
      );
      if (getNonceToSign?.status === 404) {
        throw new Error('No User found with wallet address');
      }
      if (getNonceToSign?.status !== 200) {
        throw new Error('Error logging into site using metamask');
      }
      const signedMessage =  await window.ethereum.request({
        method: 'personal_sign',
        params: [
          `Welcome to the Petaverse Network\nPlease click "Sign" in order to authenticate your metamask wallet.\n0x${this.toHex(getNonceToSign?.data['nonce'])}`,
          accounts[0],
        ],
      });
      const verifySignature = await this.platformService.postRaw(
        {
          url: this.ENDPOINT + '/metamask/verifySignedMessage',
          body: {
            address: accounts[0],
            signature: signedMessage
          }
        }
      );
      if (verifySignature?.status !== 200) {
        throw new Error('Error logging into site using metamask');
      }
      await this.auth.signInWithCustomToken(verifySignature?.data['token']).then(async (result) => {
        var expiry = new Date();
        expiry.setHours(expiry.getHours() + 1);
        let user = {
          email: result.user?.email,
          uid: result.user?.uid
        }
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('ct', JSON.stringify({ t: verifySignature?.data['token'], expiry: expiry }));
        this.router.navigate(["/setup"]);
      });
    } catch (error:any) {
      throw new Error(error.message);
    }
  }

  private toHex(stringToConvert: string) {
    return stringToConvert
      .split('')
      .map((c) => c.charCodeAt(0).toString(16).padStart(2, '0'))
      .join('');
  }

  signOut() {
    const auth = getAuth();
    auth.signOut().then(() => {
      this.router.navigate([""]);
    });
  }

}
