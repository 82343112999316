import { NgModule } from '@angular/core';
//Fireguard
import { AngularFireAuthGuard, redirectLoggedInTo, redirectUnauthorizedTo } from '@angular/fire/compat/auth-guard';

import { RouterModule, Routes } from '@angular/router';
import { StoryExistsGuard } from './guards/story-exists.guard';
import { StoryDoesntExistGuard } from './guards/story-doesnt-exist.guard';

const redirectLoggedInToAccount = () => redirectLoggedInTo(['setup']);
const redirectUnauthorizedToHome = () => redirectUnauthorizedTo(['login']);

const routes: Routes = [
  { path: 'login', loadChildren: () => import("src/app/modules/login/login.module").then(m => m.LoginModule), canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectLoggedInToAccount }},
  { path: 'setup', loadChildren: () => import("src/app/modules/setup/setup.module").then(m => m.SetupModule),  canActivate: [AngularFireAuthGuard, StoryExistsGuard], data: { authGuardPipe: redirectUnauthorizedToHome }},
  { path: 'compose', loadChildren: () => import("src/app/modules/compose/compose.module").then(m => m.ComposeModule),  canActivate: [AngularFireAuthGuard, StoryDoesntExistGuard], data: { authGuardPipe: redirectUnauthorizedToHome }},
  { path: '**', redirectTo: 'login' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
