export const environment = {
  env: "dev",
  production: false,
  platformEndpoint: "https://europe-west1-petaverse-41af3.cloudfunctions.net/v1",
  firebaseConfig : {
    apiKey: "AIzaSyB_9W7MAKjqUR-VMC1t7cmRDRFa1UccPXQ",
    authDomain: "petaverse-41af3.firebaseapp.com",
    projectId: "petaverse-41af3",
    storageBucket: "petaverse-41af3.appspot.com",
    messagingSenderId: "103159043957",
    appId: "1:103159043957:web:0b453af2941c34ecf5253c",
    measurementId: "G-VLR2DM7DWY"
  },
};
