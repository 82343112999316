import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MetamaskAuthService } from './metamask-auth.service';
import { PlatformService, PostAxiosCallProperties } from './platform.service';

@Injectable({
  providedIn: 'root'
})
export class CatgptService {

  readonly APIENDPOINT: string = environment.platformEndpoint;

  constructor(
    protected platformService: PlatformService,
    protected metamaskAuthService: MetamaskAuthService,
  ) { }

  async getPets() {
    var props: PostAxiosCallProperties =
    {
      url: this.APIENDPOINT + '/user/id',
      headers: {
        "Authorization": 'Bearer ' + await this.metamaskAuthService.token()
      }
    };
    const resp = await this.platformService.getRaw(props);
    if (resp?.data.pets) {
      return resp?.data.pets
    }
    throw new Error('');
  }

  async getPetDetails(petId: string) {

    var props: PostAxiosCallProperties =
    {
      url: this.APIENDPOINT + '/pet/' + petId,
      headers: {
        "Authorization": 'Bearer ' + await this.metamaskAuthService.token()
      }
    };

    const resp = await this.platformService.getRaw(props);

    if (resp?.data) {
      return resp?.data
    }

    throw new Error('Invalid call: ' + petId);
  }

  async getStory() {
    var props: PostAxiosCallProperties =
    {
      url: this.APIENDPOINT + '/tmt/story',
      headers: {
        "Authorization": 'Bearer ' + await this.metamaskAuthService.token()
      }
    };
    const resp = await this.platformService.getRaw(props);
    if (resp) {
      return resp
    }
    throw new Error('');
  }

  async getGenres() {
    var props: PostAxiosCallProperties =
    {
      url: this.APIENDPOINT + '/tmt/genres',
      headers: {
        "Authorization": 'Bearer ' + await this.metamaskAuthService.token()
      }
    };
    const resp = await this.platformService.getRaw(props);
    if (resp?.data) {
      return resp?.data
    }
    throw new Error('');
  }

  async createStory(selectedCat: string, selectedGenre: string) {
    var props: PostAxiosCallProperties =
    {
      url: this.APIENDPOINT + '/tmt/story',
      headers: {
        "Authorization": 'Bearer ' + await this.metamaskAuthService.token()
      },
      body: {
        "selectedCat": selectedCat,
        "selectedGenre": selectedGenre,
      }
    };
    const resp = await this.platformService.postRaw(props);
    if (resp) {
      return resp
    }
    throw new Error('');
  }

  async sendMessage(message:string) {
    var props: PostAxiosCallProperties =
    {
      url: this.APIENDPOINT + '/tmt/send-message',
      headers: {
        "Authorization": 'Bearer ' + await this.metamaskAuthService.token()
      },
      body: {
        "message": message
      }
    };
    const resp = await this.platformService.postRaw(props);
    if (resp) {
      return resp
    }
    throw new Error('');
  }

  async getSummary(type:string) {
    var props: PostAxiosCallProperties =
    {
      url: this.APIENDPOINT + '/tmt/summarise-story',
      headers: {
        "Authorization": 'Bearer ' + await this.metamaskAuthService.token()
      },
      body: {
        "type": type
      }
    };
    const resp = await this.platformService.postRaw(props);
    if (resp) {
      return resp
    }
    throw new Error('');
  }

  async endStory() {
    var props: PostAxiosCallProperties =
    {
      url: this.APIENDPOINT + '/tmt/end-story',
      headers: {
        "Authorization": 'Bearer ' + await this.metamaskAuthService.token()
      }
    };
    const resp = await this.platformService.postRaw(props);
    if (resp) {
      return resp
    }
    throw new Error('');
  }

  public async getWebsiteTerms() {

    var props : PostAxiosCallProperties =
    {
      url : this.APIENDPOINT + `/apps/terms/petaverse-website`
    };

    const resp = await this.platformService.getRaw(props);
    
    return resp?.data
  }

}
