import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { CatgptService } from '../services/catgpt.service';

@Injectable({
  providedIn: 'root'
})
export class StoryDoesntExistGuard implements CanActivate {
  constructor(
    private router: Router,
    private catgptService:CatgptService
  ) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Promise(async (resolve) => {
      const result = await this.catgptService.getStory();
      if (result.status && result.status === 200) { 
        resolve(true);
      } else {
        this.router.navigate(["/setup"]).then(() => {
          resolve(false);
        });
      }
    });
  }
}
